<script>
	// ProductDetail
	// Full detail for a product

	// Setup ---
	// Use the values below to modify how the tile appears
	const setup = {
		productNameLines: 2 // Number of lines product name is allowed to wrap before being trimmed with ellipses
	};

	export const Setup = setup;

	// Components ----
	import LimitMessages from './LimitMessages';
</script>

<script setup>
	// Imports ----
	import { reactive, ref, defineProps, computed, onMounted } from 'vue';
	import { AddToCartForm } from '@dd-nucleus/nucleus-vue';
	import { store } from '@/Store';

	// Props ----
	const props = defineProps({
		// The product whose information to show.
		// API model: DD.Nucleus.Storefront.Api.ProductDetailModel
		productId: {
			type: String,
			required: true
		}
	});

	// State ----
	const productId = ref(props.productId);
	const form = new AddToCartForm({ id: props.productId }, 1);
	const detail = reactive({ product: {} });

	// Computed ----
	const product = computed(() => {
		return detail.product;
	});

	const quantityLabel = ref('');

	const showInStockQuantity = computed(() => {
		if (product.value) {
			return product.value.limits == null || product.value.limits.length == 0;
		}
		return false;
	});

	// Handlers ----
	store.onSignedIn(async () => {
		detail.product = Object.assign(detail.product, await store.products.getProductDetail(productId.value));
		form.product = detail.product;
		form.validate();
		quantityLabel.value = store.config.store?.unitOfMeasures.find(unit => unit.id?.toLowerCase() === product.value.unitOfMeasure?.toLowerCase())?.text ?? null;
		console.log('Product is ', form.product);
	});

	const productCount = computed(() => {
		return `${product.value.quantityPerUnit} ${product.value.quantityPerUnit > 1 ? 'Units' : 'Unit'}`;
	});

	onMounted(async () => {
		window.scrollTo({
             top:  0,
             behavior: 'instant'
        });	
	});
</script>

<template>
	<div class="product-detail row">
		<div class="col-12 col-sm-7">
			<ProductImageGallery :product="product" />
		</div>

		<div class="col-12 mt-2 col-sm-5 mt-sm-0">
			<div class="d-flex flex-column">
				<div class="middle flex-fill">
					<!-- Stretchy middle part-->

					<div class="tags">
						<ProductTags :product="product" />
					</div>

					<!-- Product name and item number -->
					<div class="name" :title="product.name">
						{{ product.name }}
					</div>
					<div class="item-number">{{ product.itemNumber }}</div>
					<LimitMessages :product="product" class="mt-2" />
					<span v-if="showInStockQuantity" class="filter-messages">Qty in stock: {{ product.quantityAvailable }} </span>
					<div class="description mt-2" v-html="product.description"></div>

					<div class="mt-3 mb-3">
						<ProductFavoriteIcon :product="product" label="Add as Favorite" selected-label="Favorite" />
					</div>
				</div>
				<div class="bottom">
					<div class="row">
						<div class="col-12 col-10 col-lg-8">
							<MessageList :messages="product.messages" :add-box="false" />
							<p>Quantity: {{ productCount }} per {{ quantityLabel }}</p>
							<FormContainer :form="form" v-if="product.canAddToCart">
								<ProductAddToCartQty :product="product" />
								<FormMessages :add-box="false" class="mt-2" />
								<FieldMessages field-name="quantity" class="mt-2" />
								<div class="d-grid mt-3">
									<button class="btn btn-outline-color-1" @click="form.submit()" :disabled="form.status.isInvalid">ADD TO CART</button>
								</div>
							</FormContainer>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
	.product-detail {
		min-height: 30rem;

		p {
			font-size: 12px;
		}

		.name {
			font-size: 1.5rem;
			line-height: 1.6rem;
		}

		.title-link {
			text-decoration: none;
		}

		.item-number {
			font-size: 0.7rem;
			color: $gray-50;
		}

		.n-product-add-to-cart-qty .text-box {
			border-color: #dddddd;
		}

		.btn[disabled] {
			opacity: 0.2;
		}
	}
</style>
