<script setup>
// MarketingContacts
// Allows a user to find, create, and/or select contact(s) from their contact list.

// Constants ----
const constants = {
	DEFAULT_PAGE_SIZE: 12
};

// Components ----
import MarketingContactTile from './MarketingContactTile';

// Imports ----
import { defineProps, defineEmits, reactive, ref } from 'vue';
import { Search } from '@dd-nucleus/nucleus-vue';
import { EditContactForm } from '@dd-nucleus/nucleus-vue';
import { store, DEFAULT_COUNTRY_CODE } from '@/Store';

// Props ----
const props = defineProps({
	// Allow creation of a contact?
	allowCreate: {
		type: Boolean,
		required: false,
		default: true
	},
	// Whether to use a large, primary color button rather than an ActionButton for creating.
	largeCreateButton: {
		type: Boolean,
		required: false,
		default: false
	},
	// Whether this displaying to allow a selection
	canSelect: {
		type: Boolean,
		required: false,
		default: false
	},
	// If allowing selection, whether we're allowing multi-selection
	multiSelect: {
		type: Boolean,
		required: false,
		default: false
	},
	// If allowing selection, an array to store selected ID(s)
	selection: {
		type: Array,
		required: false,
		default: () => []
	},
	// Indicates whether the selection can be toggled on/off
	toggle: {
		type: Boolean,
		required: false,
		default: false
	}
});

// State ----
const search = new Search('mktg-contacts-for-user', 'tile', 'mktg-contact-last-name', constants.DEFAULT_PAGE_SIZE);
const editContactForm = reactive(new EditContactForm(null, null, true)); // id is null, addressconfig is null and noPoBox is true.
const favoritesOnly = ref(false);
const editingContact = ref(false);
const selectedCountry = ref(DEFAULT_COUNTRY_CODE);

const selectedContactId = ref('');

const sortOptions = [
	{ id: 'mktg-contact-last-name', text: 'Last Name (A-Z)' },
	{ id: 'mktg-contact-last-name-desc', text: 'Last Name (Z-A)' }
];

const pageSizeOptions = [12, 24, 36, 48, 60];

// Computed ----

// Events ----
const emit = defineEmits(['selected', 'created', 'deselected']);

// Handlers ----
function onSetFavoritesOnly(set) {
	favoritesOnly.value = set;

	if (favoritesOnly.value) search.setEmptyFilter('mktg-contact-favorite');
	else search.removeFilter('mktg-contact-favorite');
}

function onNewContact() {
	editContactForm.newContact();
	editContactForm.model.countryCode = selectedCountry.value;
	editingContact.value = true;
}

async function onEditContact(id) {
	await editContactForm.editContact(id);
	selectedCountry.value = editContactForm.model.countryCode;
	editingContact.value = true;
}

function onCancelEditContact() {
	editingContact.value = false;
}

async function onSaveContact() {
	await editContactForm.submit();
	editingContact.value = false;

	store.refreshSearches('addresses-for-user');
	emit('created');
}

function onContactSelected(contact) {
	selectedContactId.value = contact.id;
	emit('selected', contact);
	store.refreshSearches('addresses-for-user');
}

function onContactDeselected(contact) {
	emit('deselected', contact);
}

async function onDeleteContact(id) {
	const confirmed = confirm('Are you sure you want to delete this contact?');
	if (!confirmed) {
		return;
	}

	await store.marketing.deleteContact(id);
	store.refreshSearches(search.searchType);
}

function onChanged(form) {
	if (selectedCountry.value != form.model.countryCode) {
		selectedCountry.value = form.model.countryCode;
		form.model.state = '';
		form.model.province = '';
	}
}
</script>

<template>
	<div class="marketing-contacts">
		<button v-if="props.allowCreate && props.largeCreateButton && !editingContact" class="btn btn-color-1 float-end"
			@click="onNewContact">CREATE CONTACT</button>
		<slot></slot>
		<template v-if="!editingContact">
			<SearchContainer :search="search" :deep-link="false">
				<div class="d-flex">
					<div class="flex-fill">
						<ul class="nav nav-tabs mb-2">
							<li class="nav-item">
								<button class="nav-link" :class="{ active: !favoritesOnly }" type="button" role="tab"
									@click="onSetFavoritesOnly(false)">All Contacts</button>
							</li>
							<li class="nav-item">
								<button class="nav-link" :class="{ active: favoritesOnly }" type="button" role="tab"
									@click="onSetFavoritesOnly(true)">
									<i class="bi bi-heart"></i>My Favorite Contacts
								</button>
							</li>
						</ul>
					</div>
					<div>
						<SubPageButton v-if="props.allowCreate && !props.largeCreateButton && !editingContact"
							label="Create Contact" @click="onNewContact"></SubPageButton>
					</div>
				</div>

				<div class="filter-bar mb-3">
					<LetterPickFilter filter-id="mktg-contact-keywords" class="mb-2 d-none d-lg-block" />
					<div>
						<TextBoxFilter :resetText="false" filter-id="mktg-contact-keywords"
							placeholder="Search last name, first name, company name, address, email, or phone" />
					</div>
				</div>

				<div v-if="search.results.count > 0" class="row mb-3">
					<div class="col">
						<SortOptionsList :options="sortOptions" />
					</div>
					<div class="col">
						<PageSizeList :options="pageSizeOptions" />
					</div>
					<div class="col-4 text-end">
						<PageSelector />
					</div>
				</div>

				<SearchGrid :columns-xs="1" :columns-sm="2" :columns-md="2" :columns-lg="3" :columns-xl="3"
					:columns-xxl="3">
					<!-- Template for each item in a grid view -->
					<template v-slot:grid="contact" v-if="props.multiSelect">
						<!-- TODO: is-Selected is not working when we select multiselect-->
						<TileMultiSelectButton v-if="props.canSelect" :item="contact" :multi-select="props.multiSelect"
							:is-selected="selection.includes(contact.id)" @selected="onContactSelected(contact)"
							@deselected="onContactDeselected(contact)" :toggle="props.toggle" />
						<MarketingContactTile :class="{ selected: selection.includes(contact.id) }" :contact="contact"
							height="12rem" @edit="onEditContact(contact.id)" @delete="onDeleteContact(contact.id)" />
					</template>

					<template v-slot:grid="contact" v-else>
						<!-- TODO: is-Selected is not working when we select multiselect-->
						<!--<TileMultiSelectButton v-if="props.canSelect" :item="contact" :multi-select="props.multiSelect"
						:is-selected="selection.includes(contact.id)" @selected="onContactSelectedForOneContatct(contact)"
						:toggle="true" />-->
						<TileSelectButton :item="contact" :multi-select="false"
						:is-selected="selectedContactId === contact.id" @selected="onContactSelected(contact)" />
						<MarketingContactTile :class="{ selected: selectedContactId === contact.id }" :contact="contact"
						height="12rem" @edit="onEditContact(contact.id)" @delete="onDeleteContact(contact.id)" />
					</template>

					<!-- Template for no results -->
					<template #empty> No contacts have been created for your account.</template>

					<!-- Template while results are initially loading -->
					<template #loading-grid>
						<MockSearchGrid>
							<!-- TODO: Add mock contact tile -->
						</MockSearchGrid>
					</template>
				</SearchGrid>

				<div class="row mb-3">
					<div class="col-4 text-end">
						<PageSelector />
					</div>
				</div>
			</SearchContainer>
		</template>

		<div v-else class="subform">
			<div class="row">
				<div class="col-12 col-md-8">
					<div class="header">{{ editContactForm.model.id === null ? 'Create Contact' : 'Edit Contact' }}</div>
					<EditMarketingContact :form="editContactForm" :selectedCountry="selectedCountry" @change="onChanged" />
				</div>
				<div class="col-12 mt-3">
					<button class="btn btn-outline-color-1 me-2" @click="onCancelEditContact">Cancel</button>
					<button class="btn btn-color-1" @click="onSaveContact" :disabled="editContactForm.status.isInvalid">
						{{ editContactForm.model.id === null ? 'Create' : 'Update' }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
.marketing-contacts {

	// TODO: Move this more central
	.nav-tabs .bi-heart {
		margin-right: 0.3rem;
	}

	.n-tile-select-button {
		position: absolute;
		margin-top: -0.5rem;
		margin-left: -0.25rem;
	}

	.marketing-contact-tile.selected .n-address-tile {
		border: 1px solid $color-1 !important;
	}

	.n-button-bar {
		.btn:hover {
			color: $button-bar-selected-text-color;
			background-color: $button-bar-selected-bg-color;
		}
	}
}
</style>
